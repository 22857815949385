import { Menu } from "lucide-react";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate and useLocation
import { useAppSelector } from "../../app/hooks";
import ProfilePic from "../../assets/images/DefaultProfile.png";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import { Button } from "../../components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "../../components/ui/sheet";
import { authSelect } from "../../features/Authentication/authSlice";

const sidebarItems = [
  { name: "My Account", route: "/profile" },
  { name: "Orders", route: "/profile/orders" },
  { name: "Buy Requests", route: "/profile/buy-requests" },
  {
    name: "Seller Application (Coming soon!)",
    route: "/profile/seller-application",
  },
  // { name: "My Sales", route: "#" },
  // { name: "My Sales", route: "#" },
];

const ProfilePage = () => {
  const [open, setOpen] = React.useState(false);
  const { user, accessToken } = useAppSelector(authSelect);

  const location = useLocation(); // Get the current route
  const navigate = useNavigate(); // Hook for navigation

  // Handle route change for closing the sheet
  React.useEffect(() => {
    setOpen(false); // Close sheet on route change
  }, [location]);

  const Sidebar = () => {
    return (
      <div className="py-6">
        <nav>
          <ul className="space-y-2">
            {sidebarItems.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.route}
                  className={`block p-2 rounded-md hover:bg-gray-100 ${
                    location.pathname === item.route
                      ? "bg-blue-100 text-blue-600"
                      : ""
                  }`}
                  onClick={() => navigate(item.route)} // Close sheet on click
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Header */}
      <header className="flex items-center justify-between p-4 text-white bg-blue-1100">
        <Link to={"/"}>
          <h1 className="text-xl font-bold">Ticketnation</h1>
        </Link>
        <div className="flex items-center space-x-4">
          <Avatar>
            <AvatarImage src={ProfilePic} alt="User" />
            <AvatarFallback>{user?.firstName?.[0]}</AvatarFallback>
          </Avatar>
          <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon" className="text-white">
                <Menu className="w-6 h-6" />
                <span className="sr-only">Open menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent>
              <div className="flex items-center mb-6 space-x-4">
                <Avatar>
                  <AvatarImage src={ProfilePic} alt="User" />
                  <AvatarFallback>{user?.firstName?.[0]}</AvatarFallback>
                </Avatar>
                <div>
                  <h2 className="font-semibold">
                    {user?.firstName} {user?.lastName}
                  </h2>
                  <p className="text-sm text-gray-500">{user?.email}</p>
                </div>
              </div>
              <Sidebar />
            </SheetContent>
          </Sheet>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-1 p-4">
        <div className="mx-auto">
          {/* Render Nested Routes */}
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;
